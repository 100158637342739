.add-to-cart-container{
    display: flex;
    margin: auto;
    width: 90%;
    min-height: 70vh;
}
.Checkout-container-left{
    width: 60%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
audio{
}
.container-shipping{
    margin-top: 15px;
}
.input-name{
    display: flex;
    justify-content: space-between;
}
.input-name-left{
    width: 45%;
}
.input-name-rigth{
    width: 45%;
}
.input-name input{

}
.card-shipping h4{
    font-size: 20px;
    margin: 0;
    font-weight: 700;
    color: rgb(66, 66, 66);
}
.card-shipping p{
    margin: 0;
    font-size: 15px;
    color: rgb(66, 66, 66);
}
.card-shipping form label{
    text-decoration: underline;
    font-weight: 700;
    color: rgb(63, 63, 63);
}
.Checkout-container-left h6{
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    text-decoration: underline;
    color: rgb(66, 66, 66);
}
@media (max-width : 750px){
    .add-to-cart-container{
        flex-direction: column;
        width: 96%;
    }
    .Checkout-container-left{
        width: 100%;
    }
    
}
.Checkout-container-cart{
    display: flex;
    align-items: center;
    justify-content: center;
}