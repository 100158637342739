
.page4{
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.137);
    background-color: rgb(247, 247, 247);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 ; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.page4-img {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: relative; /* لتمكين التراكب */
}

.page4-img img {
    width: 90%;
    position: relative; /* لتمكين التراكب */
    z-index: 2; /* تأكيد أن الصورة فوق العنصر الوهمي */
}

.page4-img::after {
    content: '';
    position: absolute;
    bottom: 70px; /* تحريك الظل أسفل الصورة */
    width: 80%; /* تحديد عرض الظل بناءً على عرض الصورة */
    height: 20px; /* ارتفاع الظل */
    background: rgba(0, 0, 0, 0.582); /* لون الظل */
    border-radius: 50%; /* جعل الظل بيضاويًا ليتناسب مع شكل الظل الطبيعي */
    z-index: 1; /* الظل تحت الصورة */
    filter: blur(10px); /* إضافة تمويه للظل ليبدو طبيعيًا */
}
.page4-text{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 20px;
}
.page4-text{
    display: flex;
    flex-direction: column;
}
.page4-text h2{
    color: rgb(170,19,18);
    font-weight: 700;
    font-size: 18px;
    width: 70%;
}
.page4-text p{
    font-size: 14px;
    width: 70%;
    color: rgb(128, 128, 128);
    font-weight: 600;
}
.page4-text form{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 70%;
}
.page4-text input{
    width: 100%;
    background-color: rgba(167, 18, 18, 0.137);
    border-radius: 8px;
    border: none;
    height: 35px;
    outline: none;
    color: rgb(8, 8, 8);
}
.page4-text h3{
    font-size: 14px;
    color: rgb(128, 128, 128);
    margin: 0;
    font-weight: 600;
}
.page4-text textarea{
    height: 150px;
    outline: none;
    background-color: rgba(167, 18, 18, 0.137);
    border-radius: 8px;
    border: none;
    width: 100%;
    color: rgb(8, 8, 8);
}
@media (max-width: 900px){
    .page4-img{
        width: 100%;
    }
    .page4-text{
        width: 100%;
    }
    .page4-img::after {
        content: '';
        position: absolute;
        bottom: 20px; /* تحريك الظل أسفل الصورة */
        width: 80%; /* تحديد عرض الظل بناءً على عرض الصورة */
        height: 20px; /* ارتفاع الظل */
        background: rgba(0, 0, 0, 0.582); /* لون الظل */
        border-radius: 50%; /* جعل الظل بيضاويًا ليتناسب مع شكل الظل الطبيعي */
        z-index: 1; /* الظل تحت الصورة */
        filter: blur(10px); /* إضافة تمويه للظل ليبدو طبيعيًا */
    }
    .page4-text form{
        width: 90%;
    }
    .page4-text h2{
        width: 90%;
    }
    .page4-text p{
        width: 90%;
    }
    .page4{
        width: 100%;
        box-shadow: none;
    }
}
