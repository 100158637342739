.OrderConfirmation-container{
    background-color: rgb(248, 248, 248);
    border: 1px solid rgba(0, 0, 0, 0.226);
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
    padding: 45px;
    margin-bottom: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.OrderConfirmation-text h2{
    font-size: 24px;
    text-align: center;
    text-decoration: underline;
}
.OrderConfirmation-text p{
    font-size: 16px;
}
@media (max-width : 800px){
    .OrderConfirmation-container{
        width: 80%;
        padding:15px 0px;
    }
}
@media (max-width : 600px){
    .OrderConfirmation-container{
        width: 96%;
    }
}
.order-contferm-bottom{
    margin-top: 15px;
}
.order-contferm-bottom h2{
    font-size: 24px;
    text-align: center;
}