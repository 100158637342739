.Video-page{
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 99;
}
.Videop-container{
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.493);
    backdrop-filter: blur(12px);
    display: flex;
    align-items: center;
    position: relative;
}
.Videop-container h2{
   height: 96%;
   cursor: pointer;

}
.Videop-container h3{
   display: none;
}
.Videop-container video{
    border: 1px solid black;
    max-height: 96%;
}
.Videop-container video::-internal-media-controls-download-button {
    display: none;
}

.Videop-container video::-webkit-media-controls-enclosure {
    overflow: hidden;
}
@media (max-width: 852px){
    .Videop-container video{
        width: 100%;
        max-height: 100%;
    }
    .Videop-container h2{
        display: none;
    }
    .Videop-container h3{
        position: absolute;
        top: 2%;
        font-size: 40px;
        right: 10px;
        color: blue;
        background-color: white;
         display: flex;
         border-radius: 50%;
        cursor: pointer;
        }
}