.Header{
    background-color: rgb(233, 233, 233);
}
.Header-container{
    margin: auto;
    width: 84%;
    display: flex;
    align-items: center;
}
.Header-container-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
}
.Header-container-logo img{
    height: 35px;
}
.Header-container-logo h2{
    font-size: 18px;
    margin: 0;
    font-weight: 700;

}
.Header-container-service{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: auto;
}
.Header-container-service h6{
    margin: auto;
    padding: 0px 10px;
    color: rgb(71, 71, 71);
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    border-left: 1px solid rgb(223, 0, 0);    
}
.Header-container-service h6:hover{
    color: rgb(136, 136, 136);
}
.arrow-header{
    display: none;
}
.Header-container-bottom{
    padding: 15px 24px;
    border-top: 1px solid black;
    transition: 1s;
    animation: headerBottom 0.5s ;
    display: none;
}

@keyframes headerBottom{
    0%{
        opacity: 0;
        transform: translateY(-10px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Header-container-bottom h6{
    font-size: 18px;
    color: rgb(43, 43, 43);
}
@media (max-width: 600px){
    .Header-container-service h6{
        display: none;
    }
    .Header-container-bottom{
        display: block;
    }
    .arrow-header{
        display: flex;
        margin-bottom: none;
        font-size: 26px;
        color: black;
    }
}