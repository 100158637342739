.create-post{
    display: flex;
    flex-direction: column;
}
.create-post form{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 8px;
    margin: 20px auto;

}
.create-post form input{
    height: 40px;
    border-radius: 5px;
}
.create-post form button{
    background-color: rgb(255, 217, 0);
    height: 45px;
    border: 5px;
    border-radius: 5px;
    font-weight: 700;
}
.create-post h6{
    text-decoration: underline;
}