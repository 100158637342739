*{
    padding: 0;
    margin: 0;
}

.header{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    background-color: white;
}
.Header-container{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    width: 88%;
}
.Header-container .header-logo h2{
    color: #3f3f3f;
    font-family: sans-serif;
    font-weight: 700;
    cursor: pointer;
    font-weight: 800;
}
.Header-container .header-logo h2 span{
    color: #0455e8;
}
.Header-scroller{
    display: flex;
    gap: 14px;
}
.Header-scroller a{
    font-weight: 700;
    color: #3f3f3f;
    font-family: sans-serif;
    cursor: pointer;
    text-decoration: none;
}
.Header-scroller a:hover{
    color: #979797;
    text-decoration: underline;
}
.Home-container{
    display: flex;
    height: 93vh;
}
.Home-container-text{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    position: relative;
}
.Home-container-text h2{
    color: #3f3f3f;
    font-size: 55px;
    font-weight: 700;
    width: 74%;
    
    font-family: sans-serif
}
.Home-container-text h2 span{
    color: #0455e8;
}
.Home-container-text p{
    color: #3f3f3f;
    width: 74%;
}
.Home-container-img{
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Home-container-img img{
    height: 100%;
    animation: imgPortfolio 1s ;
    z-index: -2;
}
@keyframes imgPortfolio{
    0%{
        translate: 150PX;
    }
    100%{
        translate: 0;     
    }
}
.Home-text-btn {
    width: 74%;
}
.Home-text-btn button{
    background-color: #3f3f3f;
    color: white;
    height: 35px;
    border-radius: 8px;
    font-weight: 700;
    padding: 0 10px;
    cursor: pointer;
    border: none;
}
.Home-futter{
    position: absolute;
    bottom: 10px;
    left: 85px;
    display: flex;
    gap: 15px;
}
.Home-futter h4{
    font-size: 26px;
    cursor: pointer;
}
.Home-futter h4:hover{
    color: #979797;
}
.sidibar{
    display: none;
}
.Sidibar-portfolio{
    display: none;
}
@media (max-width: 720px){
    .Home{
        height: auto;
    }
    .Header-scroller{
        display: none;
    }
    .Home-container{
        flex-direction:column-reverse;
        height: auto;
    }
    .Home-container-img{
        width: 80%;
        margin: auto;
    }
    .Home-container-img img{
        max-width: 100%;
    }
    .Home-container-text{
        width: 100%;
        margin: auto;
        padding-bottom: 70px;
    }
    .Home-container-text h2{
        width: 80%;
        font-size: 35px;
    }
    .Home-container-text p{
        width: 80%;
    }
    .Home-text-btn{
        width: 80%;
    }
    .Home-futter{
        left: 40px;
    }
    .sidibar{
        display: flex;
        rotate: 90deg;
    }
    .sidibar a{
        font-size: 28px;
    }
    .Sidibar-portfolio{
        display: flex;
        width: 100%;
        gap: 8px;
        background-color:#0455e8;
        flex-direction: column;
        padding: 8px 0;
        position: absolute;
        animation: sidiabr 1s;
        z-index: -1;
    }
    .Sidibar-portfolio a{
        color: white;
        width: 88%;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        margin: auto;
        font-family: sans-serif;
    }
    
    @keyframes sidiabr {
        0%{
            translate: 0px -50px;
        }
    }
}
.arrow-scroller {
    position: fixed;
    background-color: #0455e8;
    color: white;
    bottom: 20px;
    right: 30px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    rotate: -100deg;
    box-shadow: rgba(0, 0, 0, 0.281) 0px 5px 15px;
    animation: arrow-scroller 1s;
}

@keyframes arrow-scroller {
    0% {
        bottom: 700px;
    }
}