.barEmail{
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3333334f;
    width: 100%;
    z-index: 12;
}
.bar-email-container{
    background-color: white;
    padding: 15px;
    width: 40%;
    border-radius: 12PX;
    display: flex;
    flex-direction: column;
}
.close-bar-email{
    direction:rtl;
    font-size: 26px;
    cursor: pointer;
}
.close-bar-email:hover{
    color: rgb(116, 116, 116);
}
.bar-email-container form{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.bar-email-container form h6{
    text-decoration: underline;
    margin-left: 5px;
}
.bar-email-container form input{
    height: 45px;
    border-radius: 8px;
    background-color: #ebeaea;
    outline: none;
    border: none;
    padding-left: 5px;
}
.bar-email-container form button{
    margin-top: 8px;
}
.bar-email-container h2{
    font-size: 16px;
}
@media (max-width:800px){
    .bar-email-container{
        width: 90%;
    }
}