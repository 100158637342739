.page-5 h2{
    font-weight: 700;
    font-size: 24px;
}
.page-5 h1{
    font-weight: 700;
    font-size: 24px;
}
.page-5 p{
    font-weight: 600;
    font-size: 13px;
}
.page-5 li{
    font-weight: 600;
    font-size: 16px;
}
.contact-page{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}