.Checkout{
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    width: 40%;
    box-shadow: rgba(38, 57, 77, 0.466) 0px 20px 30px -10px;
    background-position: center;
    background-size: cover;
    background-color: white;
    animation: sidibar 0.5s  forwards;
    overflow: auto;
}
.Container{
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    position: relative;
}
.arrow-checkout{
    color: blue;
    position: fixed;
    left: 20px;
    top: 50%;
    z-index: 3;
    font-size: 40px;
    display: none;
    background-color: white;
    border-radius: 50%;
}
@keyframes sidibar{
    0%{
        width: 0%;
    }
}
@media (max-width: 852px){
    .Checkout{
        width: 90%;
    }
    .arrow-checkout{
        display: flex;
    }
}

.Checkout-container-1{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 15px;
    gap: 10px;
}
.Checkout-container-1 .item{
    background-color: rgb(255, 255, 255);
    font-size: 18px;
    color: rgb(78, 78, 78);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(194, 194, 194);
    box-shadow: rgba(168, 168, 168, 0.24) 0px 3px 8px;}
.Checkout-card{
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 5px;
    position: relative;
    gap: 20px;
    box-shadow: rgba(168, 168, 168, 0.24) 0px 3px 8px;}
.Product-cart{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: auto;
    flex: 1;
}
.Product-cart img{
    width: 60px;
    cursor: pointer;
    height: 60px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;

}

.Product-cart-title{
    padding: 5px;
    flex: 1;
}
.Product-cart-title h2{
    margin: 0;
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    color: rgb(24, 24, 24);
}
.Product-cart-title p{
    margin: 0;
    font-size: 13px;
    width: 100%;
}

.input-cart input{
    height: 35px;
    width: 35px;
}
.price h3{
    font-size: 20px;
    font-weight: 800;
}
.price h3 span{
    font-size: 14px;
    text-decoration: line-through;
    color: rgb(117, 117, 117);
}
.Checkout-container-2{
    background-color: rgb(255, 255, 255);
    width: 100%;
}
.Checkout-card h4{
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 18px;
    cursor: pointer;
}
