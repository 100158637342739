.Page3{
    width: 86%;
    margin: auto;
}
.Page3 h2{
    text-align: center;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 700;
}
.page3-products{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 14px;
}
@media (max-width : 600px){
    .page3-products{
        gap: 0px;
    }
}
.page3-product{
    padding: 5px;
    position: relative;
}
.page3-product h3{
    position: absolute;
    font-size: 14px;
    padding: 0 5px;
    border-radius: 5px;
    top: 10px;
    left: 10px;
    color: white;
}
.page3-product:hover{
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
}
.page3-product img{
    height: 150px;
    width: 150px;
    background-color: rgb(235, 235, 235);
    border-radius: 8px;
    object-position: center;
    object-fit: cover;
    animation: imagepage3 1.5s infinite ;
}
@keyframes imagepage3{
    0% {
        background-color: rgb(219, 219, 219);
    }
    50% {
        background-color: rgb(243, 243, 243);
    }
    100% {
        background-color: rgb(221, 221, 221);
    }

}
.page3-product-text{
    display: flex;
    flex-direction: column;
}
.page3-product-text h6{
    color: rgb(122, 122, 122);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0px;
}
.page3-product-text h6 span{
    color: rgb(255, 187, 0);
}
.page3-product-text h5{
    font-weight: 600;
    font-size: 14px;
    color: rgb(90, 90, 90);
    display: flex;
    font-weight: 700;
    align-items: center;
    margin: none;
    margin-bottom: 0px;

}
.page3-product-text h4{
    font-weight: 700;
    font-size: 15px;
    color: rgb(255, 0, 0);
}
.page3-product-text h4 span{
    font-size: 10px;
    font-weight: 500;
    color: rgb(99, 99, 99);
    text-decoration:line-through;
}