.home{
    margin: auto;
}
.home #contactus{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}
::-webkit-scrollbar{
    width: 6px;
}
::-webkit-scrollbar-track{
    background-color: rgb(221, 221, 221);
}
::-webkit-scrollbar-thumb{
    border-radius:12px;
    background-color: rgb(65, 65, 65);
}
#promo{
    border-top:1px solid rgb(161, 161, 161) ;
    background-color: rgb(255, 217, 0);
    margin-bottom: 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;    
}