
.Product{
    margin: auto;
}
.Product-container{
    display: flex;
    flex-wrap: wrap;
}
.Product-container-left{
    width: 50%;
    padding: 30px;
}
.Product-container-img-div {
    height: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
    --zoom-x: 0%;
    --zoom-y: 0%;
    --display: none;
}

.Product-container-img-div::after {
    display: var(--display);
    content: "";
    width: 100%;
    height: 100%;
    background-image: var(--background-image);
    background-size: 200%; /* حجم الصورة المكبرة */
    background-position: var(--zoom-x) var(--zoom-y);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; /* لمنع التفاعل مع الصورة المكبرة */
}

.Product-container-img-div img {
    background-color: white;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    min-height: 100px;
    cursor: zoom-in;
    width: 100%; /* عرض الصورة كاملاً داخل الحاوية */
    animation: imagepage3 1.5s infinite;
}
@keyframes imagepage3{
    0% {
        background-color: rgb(235, 235, 235);
    }
    50% {
        background-color: rgb(245, 245, 245);
    }
    100% {
        background-color: rgb(235, 235, 235);
    }

}
.Product-container-imges{
    height: 45px;
    margin: 5px 0 ;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: auto;

}
.Product-container-imges::-webkit-scrollbar{
    height: 5px;
}
.Product-container-imges img{
    height: 45px;
    background-color: rgb(240, 240, 240);
    width: 45px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    animation: imageBottom 1s infinite ;
}
@keyframes imageBottom{
    0%{
        background-color: rgb(245, 245, 245);
    }
    50%{
        background-color: rgb(223, 223, 223);
    }
    100%{
        background-color: rgb(245, 245, 245);
    }
}
.Product-container-imges img:hover{
    scale: 0.9;
}
.Product-container-rigth{
    width: 50%;
    padding: 30px;
}
.Product-container-rigth h2{
    font-weight: 700;
    font-size: 24px;
}
.Product-container-rigth h3{
    font-size: 12px;
    color: rgb(133, 133, 133);
    display: flex;
    align-items: center;
    gap: 5px;
}
.Product-container-rigth h3 span{
    color: rgb(255, 196, 0);
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
}
.Product-container-rigth h4{
    color: black;
    font-weight: 700;
    font-size: 26px;
}
.Product-container-rigth h4 span{
    font-size: 12px;
    text-decoration: line-through;
    color:rgb(255, 0, 0) ;
}
.Product-container-rigth p{
    color: rgb(109, 109, 109);
    font-weight: 500;
    font-size: 14px;
}
.Product-container-rigth-btn form{
    display: flex;
    gap: 10px;
    align-items: end;    
}
.Product-container-rigth input{
    width: 45px;
    height: 40px;
    border: none;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 5px;
}
.Product-container-rigth-btn form button{
    border-radius: 5px;
    border: none;
    background-color: black;
    height: 40px;
    width: 60%;
    color: white;
}
.Product-container-rigth-btn form button:hover{
    background-color: rgb(68, 68, 68);
}
.Product-container-rigth-category h5{
    font-size: 16px;
    font-weight: 700;
    padding: 0;
}
.Product-container-rigth-category h5 span{
    color: rgb(87, 87, 87);
    font-size: 14px;
    font-weight: 500;
}
.soufiane-moutaouakil-h2{
    display: flex;
    align-items: center;
    gap: 5px;
}
.soufiane-moutaouakil{
    display: flex;
    gap: 10px;
    align-items: center;
}
.Product-container-rigth-sold-free{
    display: flex;
    align-items: center;
    position: relative;
}
.Product-container-rigth-sold{
    display: flex;
    align-items: center;
    gap: 5px;
}
.Product-container-rigth-sold h6{
    font-size: 24px;
}
.Product-container-rigth-sold img{
    height: 60px;
    width: 60px;
    object-fit: cover;
    object-position: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(214, 214, 214);
}
.Product-container-rigth-sold-free h4{
    border-radius: 5px;
    font-size: 12px;
    color: white;
    padding: 0px 6px;
    position: absolute;
    bottom: -24px;
    right: 10px;
}
.Product-container-rigth-sold-btn h5{
    font-size: 18px;
    font-weight: 700;
}
.Product-container-rigth-sold-btn button{
    background-color: black;
    border-radius: 5px;
    background-color: rgb(255, 217, 0);
    color: black;
    font-size: 12px;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
}
.Product-container-rigth-sold-btn h5 span{
    font-size: 13px;
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.596);
}

@media (max-width: 900px){
    .Product-container-left{
        width: 100%;
    }
    .Product-container-left{
        padding: 5px 0;
    }
    .Product-container-rigth{
        width: 100%;
        padding: 0px;
    }
    .Product-container-img-div{
        height: 330px;
    }
    .Product-container-img-div img{
        width: 100%;
    }
    .Product-container-imges{
        height: 60px;
        padding-left: 80px;
    }
}
.soldPub{
    background-color: rgb(247, 247, 247);
    position: fixed;
    top: 85%;
    right:0px;
    padding: 5px;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 5px 0 0 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    animation : sold 1s;
    opacity: 1 ;
    z-index: 2;
}
@keyframes sold{
    0%{
        right: -20px;
    }
 
}
